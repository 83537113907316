<template>
	<v-list-item>
		<v-list-item-icon>
			<v-icon
				:color="document.principal ? 'primary' : ''"
				v-text="document.principal ? 'mdi-star' : 'mdi-star-outline'"
				@click="callToogleDocumentPrincipal(document)"
			/>
		</v-list-item-icon>
		<v-list-item-content>
			<v-list-item-title v-text="document.name" />
			<v-list-item-subtitle v-text="document.principal ? `(${$t('documents.principal')}) ${document.type.name}` : document.type.name" />
		</v-list-item-content>

		<v-list-item-action>
			<v-row>
				<v-col>
					<v-chip
						small
						:ripple="false"
						:color="document.uploaded ? 'success' : 'error'"
						class="mx-1"
						@click="document.uploaded ? callDownloadDocument(document) : callNoDocument()"
					>
						<v-icon left> mdi-cloud-download </v-icon>
						{{ document.name }}
					</v-chip>

					<v-dialog v-model="dialog" persistent max-width="350">
						<template v-slot:activator="{ on }">
							<v-btn icon color="error" v-on="on">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</template>
						<v-card rounded="xl">
							<v-card-title>
								<span class="headline">{{ $t('courses.areYouSure') }}</span>
							</v-card-title>
							<v-card-actions>
								<v-spacer />
								<v-btn color="blue darken-1" text rounded @click="dialog = false"> No </v-btn>
								<v-btn color="error darken-1" text rounded @click="callDeleteDocument(document)">
									{{ $t('settings.delete') }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-col>
			</v-row>
		</v-list-item-action>
	</v-list-item>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'SettingsDocumentsAllItem',
	props: {
		document: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			loading: true,
			dialog: false,
		}
	},
	methods: {
		callDownloadDocument(document) {
			this.loading = true
			this.downloadDocument({ documentID: document.id }).then(() => {
				this.loading = false
			})
		},
		callDeleteDocument(document) {
			this.loading = true
			this.deleteDocument({ documentID: document.id }).then(() => {
				this.loading = false
			})
		},
		callToogleDocumentPrincipal(document) {
			this.loading = true
			this.toogleDocumentPrincipal({ documentID: document.id, principal: !document.principal }).then(() => {
				setTimeout(() => {
					this.loading = false
				}, 1000)
			})
		},
		callNoDocument() {
			this.noDocument()
		},
		...mapActions('documents', ['downloadDocument', 'toogleDocumentPrincipal', 'deleteDocument', 'noDocument']),
	},
}
</script>
